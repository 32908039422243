import { Box, Button, Typography } from '@mui/material';
import React, { useContext,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginHeader from '../header';
import Footer from '../footer';
import { RegistrationContext } from '../context/registrationProvider';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';


const SubscriptionOff = () => {

    const navigate = useNavigate();
    const { canAccessStepRegistration, allowStepRegistrationAccess, userData, setUserData } = useContext(RegistrationContext);

    const handleContinue = () => {
        allowStepRegistrationAccess();
        setUserData({
            nome: '',
            cognome: '',
            dataNascita: '',
            incarico: '',
            cellulare: '',
            email: '',
            password: '',
            confirmPassword: '',
            externalId:userData.externalId,
            idAccount:userData.idAccount
        })
        navigate('/register/choice/plane');
    };

    useEffect(() => {
        if (!canAccessStepRegistration) {
            navigate('/login');
        }
    }, [canAccessStepRegistration, navigate]);

    return (
        <>
            <LoginHeader isRegister={true} />
            <Box component="form" noValidate sx={{
                overflowX: 'hidden',
                overflowY: 'hidden',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
                <Box sx={{ flexGrow: 1, textAlign: 'center', fontFamily: 'Ruffina' }}>
                    <Typography variant="body2" align="center" sx={{ color: 'gray', mb: 3, fontFamily: 'Ruffina' }}>
                        Passaggio 1 di 2
                    </Typography>
                    <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Ruffina', maxWidth: '30%', mx: 'auto' }}>
                    Il tuo abbonamento è scaduto
                    </Typography>

                    <Typography variant="body1" align="center" sx={{ color: 'gray', mb: 3, fontFamily: 'Ruffina', maxWidth: '18%', mx: 'auto' }}>
                    Rinnova o modifica il tuo piano di abbonamento è scaduto procedi al pagamento.                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleContinue}
                            sx={{ background: '#FDC800', color: 'black' }}
                        >
                            RINNOVA ABBONAMENTO
                        </Button>
                    </Box>
                </Box>

                <Footer />
            </Box>
        </>
    );
};

export default SubscriptionOff;
