import React, { useContext, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Divider, IconButton, Box } from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon, MenuOpenOutlined } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import {useAuth} from '../context/authContext'
import { RegistrationContext } from '../context/registrationProvider';


const Sidebar = ({ onMenuClick }) => {
    const [open, setOpen] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState('home');
    const navigate = useNavigate();
    const { user, rememberMeEffect, saveUser, saveToken } = useAuth()  || {};

    const toggleSidebar = () => {
        setOpen(!open);
    };
    const handleListItemClick = (key) => {
        setSelectedIndex(key);
        onMenuClick(key);
    };

    const handleHomeClick = () => {
        
    };

    const handleUtentiClick = () => {
        
    };

    const handleLogoutClick = () => {
        saveToken(null, null);
        saveUser(null,false);
        navigate('/login')
    };
    return (
        <Box sx={{ display: 'flex', position: 'relative', flexDirection: 'column' }}>
            <IconButton
                onClick={toggleSidebar}
                sx={{
                    position: 'fixed',
                    top: 77,
                    left: open ? 160 : 10,
                    transition: 'left 0.3s ease',
                    zIndex: 1100,
                    backgroundColor: '#FDC800',
                    borderRadius: '50%',
                    color:'black',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                        backgroundColor: '#FDC800',
                    }
                }}
            >
                <MenuOpenOutlined />
            </IconButton>


            <Drawer
                sx={{
                    width: 200,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: open ? 200 : 0,
                        boxSizing: 'border-box',
                        position: 'fixed',
                        top: '77px',
                        left: 0,
                        bottom: 0,
                        zIndex: 1000,
                        maxHeight: '60vh',
                        transition: 'width 0.3s ease',
                    },
                }}
                variant="permanent"
                open={open}
            >
                <List>
                <ListItem 
                    button 
                    onClick={() => handleListItemClick('home')} 
                    sx={{
                        cursor:'pointer',
                        color: selectedIndex === 'home' ? 'black' : 'grey',
                        '&:hover': {
                            color: selectedIndex === 'home' ? 'black' : 'grey',
                        },
                    }}
                >
                    <ListItemText primary="Home" />
                </ListItem>

                <ListItem 
                    button 
                    onClick={() => handleListItemClick('register')} 
                    sx={{
                        cursor:'pointer',
                        color: selectedIndex === 'register' ? 'black' : 'grey',
                        '&:hover': {
                            color: selectedIndex === 'register' ? 'black' : 'grey',
                        },
                    }}
                >
                    <ListItemText primary="Utenti" />
                </ListItem>

                <ListItem 
                    button 
                    onClick={() => handleListItemClick('code')} 
                    sx={{
                        cursor:'pointer',
                        color: selectedIndex === 'code' ? 'black' : 'grey',
                        '&:hover': {
                            color: selectedIndex === 'code' ? 'black' : 'grey',
                        },
                    }}
                >
                    <ListItemText primary="Code" />
                </ListItem>
                </List>

                <Divider />

                <List>
                <ListItem 
                    button 
                    onClick={() => handleLogoutClick()} 
                    sx={{
                        cursor:'pointer',
                        color: selectedIndex === 'logout' ? 'black' : 'grey',
                        '&:hover': {
                            color: selectedIndex === 'logout' ? 'black' : 'grey',
                        },
                    }}
                >
                    <ListItemText primary="Logout" />
                </ListItem>
                </List>
            </Drawer>
        </Box>
    );
};

export default Sidebar;
