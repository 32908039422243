const path='https://service.annuariopontificio.catholic';
const vaticano='https://www.annuariopontificio.catholic'
const api = {
    path:path,
    vaticano:vaticano,
    createAccount:'/api/accounts/create',
    getCatalog:'/api/view/catalog',
    login:'/api/login',
    checkout:'/api/payment',
    redirectFlow:'/api/redirectFlow',
    trigger:'/api/trigger',
    info:'/api/info',
    subscription:'/api/subscription',
    plugin:'/api/view/plugin',
    captcha:'/captcha',
    captchaVerify:'/captchaverify',
    incaricogetAll:'/api/incarico',
    token:'/api/clientToken',
    userGetAll:'/api/utenti',
    delete:'/api/utente/deleted',
    search:'/api/search',
    userDetails:'/api/utente/details',
    modifyUser:'/api/utente/modify',
    free:'/api/code',
    createUserBack:'/api/accounts/back',
    updateUser:'/api/accounts/update',
    allCode:'/api/view/code'
}

export default api;