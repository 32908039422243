
import axios from 'axios';
import api from '../../utils/proxy.js';

const userService = {
    getAll: async (data, token) => {
        try {
            const response = await axios.post(api.path + api.userGetAll, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Errore durante la richiesta del captcha:', error);
            throw error;
        }
    },
    delete:async (data, token) => {
        try {
            const response = await axios.post(api.path + api.delete, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Errore durante la richiesta del captcha:', error);
            throw error;
        }
    },
    search:async (data, token) => {
        try {
            const response = await axios.post(api.path + api.search, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Errore durante la richiesta del captcha:', error);
            throw error;
        }
    },
    userDetail:async (data, token) => {
        try {
            const response = await axios.post(api.path + api.userDetails, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Errore durante la richiesta del captcha:', error);
            throw error;
        }
    },
    updateUser:async (data, token) => {
        try {
            const response = await axios.post(api.path + api.modifyUser, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Errore durante la richiesta del captcha:', error);
            throw error;
        }
    },
    createUserBackOffice:async (data, token) => {
        try {
            const response = await axios.post(api.path + api.createUserBack, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Errore durante la richiesta del captcha:', error);
            throw error;
        }
    },
};

export default userService;