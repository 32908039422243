import axios from 'axios';
import api from '../../utils/proxy.js';

const accountService = {
    register: async (data) => {
        try {
            const response = await axios.post(api.path + api.createAccount, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data; 
        } catch (error) {
            console.error('Errore durante la richiesta del captcha:', error);
            throw error;
        }
    },
    update:async (data) => {
        try {
            const response = await axios.post(api.path + api.updateUser, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data; 
        } catch (error) {
            console.error('Errore durante la richiesta del captcha:', error);
            throw error;
        }
    },

    deleteSubscribeAbboinment:async (data) => {
        try {
            const response = await axios.post(api.path + api.deleteAbbo, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data; 
        } catch (error) {
            console.error('Errore durante la richiesta del captcha:', error);
            throw error;
        }
    },
};

export default accountService;
