import React from 'react';
import { Typography, Link, Box, Grid } from '@mui/material';
import TwitterX from './assets/xicon.png';
import { useAuth } from './context/authContext';
import api from '../utils/proxy';
const Footer = () => {
    const { token } = useAuth();

    return (
        <>
            <Box
                sx={{
                    mt: 4,
                    p: 2,
                    bgcolor: '#f8f8f8',
                    overflow: 'hidden',
                    width: '100%',
                    position: 'relative',
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflowX: 'hidden'
                }}
            >
                <Box
                    sx={{

                        width: '60%',
                        padding: '16px 8px 6px 8px',
                        marginTop: '-1%'
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            left: '20.6%',
                            top: '4px',
                            width: '56.7%',
                            height: '1px',
                            backgroundColor: '#ccc',
                        }}
                    />
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={3} sx={{ textAlign: 'left' }}>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                <Link href="https://www.vatican.va/content/romancuria/it/segreteria-di-stato/segreteria-di-stato.index.html" sx={{ display: 'block', color: '#000000DE' }}>
                                    Segreteria di Stato
                                </Link>
                                <Link href={api.vaticano + '/annuario-pontificio'} sx={{ display: 'block', color: '#000000DE' }}>
                                    Annuario Pontificio
                                </Link>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    {token && (
                                        <Link href="#" sx={{ display: 'block', color: '#000000DE' }}>
                                            Note Storiche
                                        </Link>
                                    )}
                                    <Link href={api.vaticano + '/domande-frequenti'} sx={{ display: 'block', color: '#000000DE' }}>
                                        Domande Frequenti
                                    </Link>
                                    <Link href={api.vaticano + '/contattaci'} sx={{ display: 'block', color: '#000000DE' }}>
                                        Contattaci
                                    </Link>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="body2" sx={{ mb: 1 }}>
                                    <Link href={api.vaticano + '/privacy-policy'} sx={{ display: 'block', color: '#000000DE' }}>
                                        Privacy
                                    </Link>
                                    <Link href={api.vaticano + '/cookie-policy'} sx={{ display: 'block', color: '#000000DE' }}>
                                        Preferenze per i cookie
                                    </Link>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={3} sx={{ textAlign: 'left' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                                <Box component="img" src={TwitterX} alt="Custom Icon" sx={{ mb: 2, height: '25px', width: '25px' }} />
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>SEGRETERIA DI STATO</Typography>
                                <Typography variant="body2" sx={{ fontSize: '12px', color: 'gray' }}>
                                    Palazzo Apostolico
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: '12px', color: 'gray' }}>
                                    00120, Città Del Vaticano
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: '12px', color: '#000000DE' }}>
                                    <Link href="mailto:annuariopontificio@sds.va" sx={{ color: '#000000DE', textDecoration: 'underline' }}>
                                        annuariopontificio@sds.va
                                    </Link>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{ width: '100%', textAlign: 'left', pl: 0, mt: 3 }}>
                        <Typography variant="body2" sx={{ fontSize: '12px', color: 'gray' }}>
                            © 2024 - Segreteria di Stato della Santa Sede
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Footer;
