import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Box } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning'; 

const ConfirmationDialog = ({ open, onClose, onConfirm, title, message }) => {
    return (
        <Dialog open={open} onClose={onClose}  maxWidth="xs" fullWidth sx={{ '& .MuiDialog-container': { justifyContent: 'center' } }}>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#F8F8F8', color: '#000', padding: '16px' }}>
                {title}
            </DialogTitle>
            <DialogContent sx={{ padding: '24px', backgroundColor: 'white', marginTop:'10px' }}>
                <Typography sx={{ color: '#00000099', fontSize: '16px' }}>{message}</Typography>
            </DialogContent>
            <DialogActions sx={{ padding: '16px', justifyContent: 'flex-end' }}>
                <Button 
                    onClick={onConfirm} 
                    sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '10px 20px',
                        borderRadius: '0px',
                        border:'none'
                    }}
                >
                    Conferma
                </Button>
                <Button 
                    onClick={onClose} 
                    sx={{
                        backgroundColor: 'white',
                        color: '#DE000F',
                        padding: '10px 6px',
                        borderRadius: '0px',
                        boder:'none',
                        
                    }}
                >
                    ESCI
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
