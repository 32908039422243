import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Card, CardContent, Typography, CircularProgress, Grid, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import LoginHeader from '../header';
import Footer from '../footer';
import '../../css/style.css';
import { useNavigate } from 'react-router-dom';
import { RegistrationContext } from '../context/registrationProvider';
import catalogService from '../services/catalogservice';
import xml2js from 'xml2js';
import paymentService from '../services/paymentservice';

const ChoicePlane = () => {
    const navigate = useNavigate();
    const { canAccessStepRegistration, allowStepRegistrationAccess, userData } = useContext(RegistrationContext);
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isAnnualPlanSelected, setIsAnnualPlanSelected] = useState(false);

    const title = "il più selezionato";

    useEffect(() => {
        if (!canAccessStepRegistration) {
            navigate('/login');
        }

        const fetchPlans = async () => {
            try {
                setLoading(true);
                const response = await catalogService.getCatalog();
                const xmlString = response.data;
                const parser = new xml2js.Parser();

                parser.parseString(xmlString, (err, result) => {
                    if (err) {
                        setError(err);
                        return;
                    }
                    const extractedPlans = result.catalogs?.versions[0]?.version.reduce((acc, version) => {
                        const plans = version.plans[0]?.plan || [];
                        const plansWithCategory = plans.map(plan => ({
                            ...plan,
                            productName: plan.product?.[0] || 'Unknown Product',
                            planName: plan.$.name || 'Unnamed Plan',
                            prettyName: plan.$.prettyName || 'Unnamed',
                            initialTrialDuration: plan.initialPhases?.[0]?.phase?.[0]?.duration?.[0] || 'N/A',
                            recurringPrice: plan.finalPhase[0]?.recurring[0]?.recurringPrice[0]?.price[0] || { value: '0', currency: 'N/A' },
                            billingPeriod: plan.finalPhase[0]?.recurring[0]?.billingPeriod[0] || 'Monthly'
                        }));
                        return acc.concat(plansWithCategory);
                    }, []) || [];

                    const filteredPlans = extractedPlans.filter(plan => plan.planName !== "free_plan");
                    setPlans(filteredPlans);

                    const defaultPlan = filteredPlans.find(plan => plan.billingPeriod === 'ANNUAL');
                    if (defaultPlan) {
                        setSelectedPlan(defaultPlan);
                    }
                });
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchPlans();
    }, [canAccessStepRegistration, navigate]);

    const handleBackButtonClick = () => {
        navigate('/register/choice');
    };

    const handleSelectPlan = (event) => {
        const selected = selectedPlan;
        setIsAnnualPlanSelected(selected.prettyName === 'Annual Plan');
    };

    const handleSubmit = async () => {
        console.log(userData);
        if (selectedPlan) {
            const planData = {
                billingPeriod: selectedPlan.billingPeriod,
                price: selectedPlan.recurringPrice.value,
                planName: selectedPlan.planName
            };

            const data = {
                externalKey: userData.externalId,
                accountId: userData.idAccount,
                planName: selectedPlan.planName
            }
            try {
                const response = await paymentService.subscribe(data);
                if (response && response.status === 201) {
                    allowStepRegistrationAccess();
                    navigate('/register/choice/methodpayment', { state: planData });
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            alert('Please select a plan before continuing.');
        }
    };

    const handleBonus = () => {
        allowStepRegistrationAccess();
        navigate('/register/bonus');
    };

    return (
        <>
            <LoginHeader isRegister={true} sx={{ mt: 8 }} />
            <Box component="form" noValidate sx={{
                overflowX: 'hidden',
                overflowY: 'hidden',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                pr: 10,
                width: '100%'

            }}>
                <Box sx={{ padding: 0, mt: 0 }}>
                    {loading && <CircularProgress />}
                    {error && <Typography color="error">{error.message}</Typography>}
                    {!loading && !error && plans.length === 0 && <Typography>Nessun piano disponibile</Typography>}

                    <Box sx={{ flexGrow: 1, textAlign: 'center', mb:0, mt:9,pr:4, marginBottom:'-115px'}}>
                        <Typography variant="body2" align="center" sx={{ color: 'gray', mb: 2, fontFamily: 'Open Sans' }}>
                            Passaggio 2 di 3
                        </Typography>
                        <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Rufina', maxWidth: '30%', mx: 'auto', }}>
                            Scegli il tuo abbonamento
                        </Typography>
                    </Box>



                    <FormControl component="fieldset" fullWidth>
                        <RadioGroup
                            aria-label="select-plan"
                            name="plan"
                            value={selectedPlan ? selectedPlan.planName : ''}
                            onChange={handleSelectPlan}
                            sx={{
                                color: selectedPlan ? '#FDC800' : 'grey',
                                '&.Mui-checked': {
                                    color: '#FDC800',
                                },
                                width: '100%',
                                mt:0,
                               
                            }}
                        >
                            <Grid
                                container
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: { xs: 'auto', sm: 'auto', md: '80vh' }, 
                                    padding: '1rem', 
                                    display: 'flex',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        maxWidth: '556px',
                                        
                                        position: 'relative',
                                        height: 'auto',
                                        padding: '1rem',
                                        margin: '0 auto',
                                        overflow:'hidden',
                                        marginTop:'136px'
                                    }}
                                >
                                    {plans.map((plan) => (
                                        <React.Fragment key={plan.planName}>
                                            {plan.prettyName === 'Annual Plan' && (
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        position: 'relative',
                                                        top: '0px',
                                                        left: '0px',
                                                        backgroundColor: !isAnnualPlanSelected ? '#FDC800' : '#F1F1F1',
                                                        color: 'black',
                                                        padding: '5px 15px',
                                                        borderRadius: '4px',
                                                        fontSize: { xs: '12px', sm: '14px', md: '14px' },
                                                        fontFamily: 'Open Sans',
                                                        zIndex: 2,
                                                        whiteSpace: 'nowrap',
                                                        width: 'fit-content',
                                                        height: 'auto',
                                                        fontWeight: '600',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {title.toUpperCase()}
                                                </Typography>
                                            )}
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    marginBottom: 3,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Card
                                                    sx={{
                                                        width: { xs: '556px', sm: '556px', md: '556px' }, 
                                                        height: { xs: '82px', sm: '82px', md: '82px' },
                                                        border: 1,
                                                        borderColor: selectedPlan === plan ? '#FDC80090' : 'grey.400',
                                                        backgroundColor: selectedPlan === plan ? '#FDC80029' : 'transparent',
                                                        borderRadius: 2,
                                                        cursor: 'pointer',
                                                        textAlign: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        padding: '10px 20px',
                                                        boxShadow: selectedPlan === plan ? '0px 4px 20px rgba(0, 0, 0, 0.1)' : 'none',
                                                        margin: '0 auto',
                                                    }}
                                                    onClick={() => {
                                                        setSelectedPlan(plan);
                                                        handleSelectPlan();
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                checked={selectedPlan === plan}
                                                                value={plan.planName}
                                                                sx={{
                                                                    color: selectedPlan === plan ? '#FDC800' : 'grey',
                                                                    '&.Mui-checked': {
                                                                        color: '#FDC800',
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label=""
                                                        sx={{ marginRight: 0 }}
                                                    />
                                                    <CardContent
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            width: '450px',
                                                            height:'40px',
                                                            flexWrap: { xs: 'wrap', md: 'nowrap' },
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h5"
                                                            sx={{
                                                                fontFamily: 'Rufina',
                                                                fontSize: { xs: '18px', sm: '20px', md: '24px' },
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            Piano{' '}
                                                            {plan.prettyName === 'Quarterly Plan'
                                                                ? 'Trimestrale'
                                                                : 'Annuale'}
                                                        </Typography>
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                fontFamily: 'Open Sans',
                                                                fontSize: { xs: '14px', sm: '16px', md: '18px' },
                                                                textAlign: 'center',
                                                                marginTop: { xs: '8px', md: '0' },
                                                            }}
                                                        >
                                                            {plan.recurringPrice.value} €/
                                                            {plan.prettyName === 'Quarterly Plan' ? 'trim.' : 'anno'}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                                
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </Grid>


                        </RadioGroup>
                    </FormControl>

                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '-38px',width:'100%',mb:35}}>
                        <Button
                            onClick={handleBackButtonClick}
                            sx={{ width: '8%', color: 'black', background: 'white', border: '1px solid black', marginRight: '12.4%' }}
                        >
                            INDIETRO
                        </Button>
                        <Button
                            sx={{ width: '8%', color: '#0587D1', background: 'white', border: 'none' }}
                            onClick={handleBonus}
                            disabled={isSubmitting}
                        >
                            USA BONUS
                        </Button>

                        <Button
                            sx={{ width: '8%', color: 'black', background: '#FDC800' }}
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'CONTINUA'}
                        </Button>
                    </Box>
                </Box>



            </Box>


            <Footer />
        </>
    );
};

export default ChoicePlane;
