import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import LoginHeader from '../header';
import Footer from '../footer';
import '../../css/style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { RegistrationContext } from '../context/registrationProvider';
import ConfirmationDialog from '../dialog';
import incaricoService from '../services/incaricoservice';

const UserRegistration = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isRegister = location.state?.isRegister;
    const { canAccessStepRegistration, allowStepRegistrationAccess, userData, setUserData } = useContext(RegistrationContext);

    const [nome, setNome] = useState(userData.nome ?? '');
    const [cognome, setCognome] = useState(userData.cognome ?? '');
    const [dataNascita, setDataNascita] = useState(userData.dataNascita ?? '');
    const [incarico, setIncarico] = useState(userData.incarico ?? '');
    const [cellulare, setCellulare] = useState(userData.cellulare ?? '');
    const [errors, setErrors] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [incarichi, setIncarichi] = useState([]);
    const today = new Date();
    const maxDate = new Date(today.setFullYear(today.getFullYear() - 18));
    const maxDateString = maxDate.toISOString().split('T')[0];

    useEffect(() => {
        const getIncarichi = async () => {
            try {
                const response = await incaricoService.getAll();
                setIncarichi(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                setIncarichi([]);
            }
        };

        getIncarichi();
    }, []);
    
    const validateAge = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age >= 18;
    };
    
    const validateForm = () => {
        let tempErrors = {};
        const namePattern = /^[a-zA-ZÀ-ÿ' ]+$/;
        const phonePattern = /^[0-9]{10}$/;

        if (!nome) {
            tempErrors.nome = "Il nome è obbligatorio";
        } else if (!namePattern.test(nome)) {
            tempErrors.nome = "Il nome può contenere solo lettere e apostrofi";
        }

        if (!cognome) {
            tempErrors.cognome = "Il cognome è obbligatorio";
        } else if (!namePattern.test(cognome)) {
            tempErrors.cognome = "Il cognome può contenere solo lettere e apostrofi";
        }

        if (!dataNascita) {
            tempErrors.dataNascita = "La data di nascita è obbligatoria";
        } else if (!validateAge(dataNascita)) {
            tempErrors.dataNascita = "Devi essere maggiorenne per completare la registrazione";
        }

        if (!incarico) {
            tempErrors.incarico = "L'incarico è obbligatorio";
        }

        if (!cellulare) {
            tempErrors.cellulare = "Il cellulare è obbligatorio";
        } else if (!phonePattern.test(cellulare)) {
            tempErrors.cellulare = "Il cellulare deve contenere solo 10 numeri";
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            allowStepRegistrationAccess();
            setUserData({
                nome: nome,
                cognome: cognome,
                dataNascita: dataNascita,
                incarico: incarico,
                cellulare: cellulare
            });
            navigate('/register/login');
        }
    };

    const handleBackButtonClick = () => {
        setOpenDialog(true);
    };

    const handleConfirmExit = () => {
        setUserData({
            nome: '',
            cognome: '',
            dataNascita: '',
            incarico: '',
            cellulare: '',
            email: '',
            password: '',
            confirmPassword: ''
        });

        setOpenDialog(false);
        navigate('/register');
    };

    const handleCancelExit = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <LoginHeader isRegister={true}/>
            <Box
                sx={{
                    maxWidth: 492,
                    mx: 'auto',
                    pl: 0,
                    pr:2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 2,
                    overflowX: 'hidden',
                    pr:3,
                }}
            >
                <Typography variant="body2" align="center" sx={{ color: 'gray', mb: 2,mt:4, fontFamily:'Open Sans'}}>
                    Passaggio 1 di 3
                </Typography>

                <Typography variant="h4" align="center" gutterBottom sx={{fontFamily:'Rufina', fontWeight:'400',fontSize:'34px',letterSpacing:'1px'}}>
                    Inserisci i tuoi dati
                </Typography>

                <Box component="form" noValidate sx={{ width: '100%', mt: 3, mb:25 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Nome"
                                required
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                error={!!errors.nome}
                                helperText={errors.nome}
                                inputProps={{ maxLength: 50 }}
                                InputLabelProps={{
                                    style: { fontSize: '16px', color: '#666', top: '5px' }
                                }}
                                sx={{width:'492px'}}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Cognome"
                                required
                                value={cognome}
                                onChange={(e) => setCognome(e.target.value)}
                                error={!!errors.cognome}
                                helperText={errors.cognome}
                                inputProps={{ maxLength: 50 }}
                                InputLabelProps={{
                                    style: { fontSize: '16px', color: '#666', top: '5px' }
                                }}
                                sx={{width:'492px'}}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Data di Nascita"
                                required
                                type="date"
                                value={dataNascita}
                                onChange={(e) => setDataNascita(e.target.value)}
                                error={!!errors.dataNascita}
                                helperText={errors.dataNascita}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: '16px', color: '#666', top: '5px' }
                                }}
                                inputProps={{
                                    max: maxDateString
                                }}
                                sx={{width:'492px'}}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                label="Incarico"
                                required
                                value={incarico}
                                onChange={(e) => setIncarico(e.target.value)}
                                error={!!errors.incarico}
                                helperText={errors.incarico}
                                InputLabelProps={{
                                    style: { fontSize: '16px', color: '#666', top: '5px' }
                                }}
                                sx={{width:'492px'}}
                            >
                                {incarichi.map((incarico) => (
                                    <MenuItem key={incarico.idIncarico} value={incarico.nomeIncarico}>
                                        {incarico.nomeIncarico}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Cellulare"
                                required
                                value={cellulare}
                                onChange={(e) => setCellulare(e.target.value)}
                                error={!!errors.cellulare}
                                helperText={errors.cellulare}
                                inputProps={{ maxLength: 10 }}
                                InputLabelProps={{
                                    style: { fontSize: '16px', color: '#666', top: '5px' }
                                }}
                                sx={{width:'492px'}}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4,mb:8 }}>
                        <Button
                            variant="contained"
                            sx={{ width: '26%', color: 'black', background: 'white',border:'1px solid' }}
                            onClick={handleBackButtonClick}
                        >
                            INDIETRO
                        </Button>
                        <Button
                            variant="contained"
                            color="warning"
                            sx={{ width: '26%', color: 'black', background: '#FDC800' }}
                            onClick={handleSubmit}
                        >
                            CONTINUA
                        </Button>
                    </Box>
                </Box>

                <ConfirmationDialog
                    open={openDialog}
                    onClose={handleCancelExit}
                    onConfirm={handleConfirmExit}
                    title="Uscire dalla registrazione?"
                    message="Interrompendo il processo di registrazione perderai i dati inseriti fin qui e dovrai iniziare nuovamente."
                />
            </Box>
            <Footer sx={{mt:19,marginTop:19}}/>
        </>
    );
};

export default UserRegistration;
