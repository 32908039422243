import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './components/homepage';
import LoginPage from './components/login/loginPage';
import StartRegistration from './components/registration/startRegistration';
import { RegistrationProvider } from './components/context/registrationProvider';
import UserRegistration from './components/registration/userRegistration';
import UserRegistrationLogin from './components/registration/userRegistrationLogin';
import UserStartChoicePlane from './components/registration/userStartChoicePlane';
import PaymentAccepted from './components/registration/paymentAccepted';
import PaymentRejected from './components/registration/paymentRejected';
import ChoiceMethodPayment from './components/registration/choiceMethodPayment';
import InsertDataPayment from './components/registration/insertDataPayment';
import ChoicePlane from './components/registration/choicePlane';
import { AuthProvider } from './components/context/authContext';
import HomeBack from './components/backoffice/backOffice';
import PrivateRoute from './components/context/privateRoute';
import EditUser from './components/backoffice/editUser';
import UseBonus from './components/registration/useBonus';
import BonusAccepted from './components/registration/bonusAccepted';
import SubscriptionOff from './components/registration/subscriptionOff';

const App = () => {
    
    return (
        <AuthProvider>
        <RegistrationProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<HomePage />} />
                    <Route path="/register" element={<StartRegistration />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/registration/user" element={<UserRegistration/>}/>
                    <Route path='/register/login' element={<UserRegistrationLogin/>}/>
                    <Route path='/register/choice' element={<UserStartChoicePlane/>}/>
                    <Route path='/register/choice/methodpayment' element={<ChoiceMethodPayment/>}/>
                    <Route path='/accepted' element={<PaymentAccepted/>}/>
                    <Route path='/rejected' element={<PaymentRejected/>}/>
                    <Route path='/payment' element={<InsertDataPayment/>}/>
                    <Route path='/register/choice/plane' element={<ChoicePlane/>}/>
                    <Route path='/dashoboard' element={<PaymentRejected/>}/>
                    <Route path='/bonus/accepted' element={<BonusAccepted/>}/> 
                    <Route path='/register/bonus' element={<UseBonus/>}/>
                    <Route path="/back/home" element={<PrivateRoute element={<HomeBack />} requiredRole="admin"/>} />
                    <Route path="/back/edit" element={<PrivateRoute element={<EditUser />} requiredRole="admin"/>} />
                    <Route path='/subscription/off' element={<SubscriptionOff/>}/>
                    {/* Aggiungi PrivateRoute per le rotte che devono essere protette */}
                    {/* <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
                    <Route path="/profile" element={<PrivateRoute element={<Profile />} />} /> */}
                    <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
            </Router>
        </RegistrationProvider>
        </AuthProvider>
    );
};

export default App;
