import React, { createContext, useState } from 'react';

export const RegistrationContext = createContext();

export const RegistrationProvider = ({ children }) => {
    const [userData, setUserData] = useState({
        nome: '',
        cognome: '',
        dataNascita: '',
        incarico: '',
        cellulare: '',
        email:'',
        password:'',
        confirmPassword:'',
        idAccount:'',
        idAccountBraintree:'',
        externalKey:''
    });
    const [canAccessStepRegistration, setCanAccessStepRegistration] = useState(false);

    const allowStepRegistrationAccess = () => setCanAccessStepRegistration(true);

    return (
        <RegistrationContext.Provider value={{ canAccessStepRegistration, allowStepRegistrationAccess,userData,setUserData }}>
            {children}
        </RegistrationContext.Provider>
    );
};
