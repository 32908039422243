import { Box, Button, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginHeader from '../header';
import Footer from '../footer';
import { RegistrationContext } from '../context/registrationProvider';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';


const BonusAccepted = () => {

    const navigate = useNavigate();
    const { canAccessStepRegistration, allowStepRegistrationAccess, userData, setUserData } = useContext(RegistrationContext);

    const handleContinue = () => {
        allowStepRegistrationAccess();
        setUserData({
            nome: '',
            cognome: '',
            dataNascita: '',
            incarico: '',
            cellulare: '',
            email: '',
            password: '',
            confirmPassword: ''
        })
        navigate('/login');
    };

    useEffect(() => {
        if (!canAccessStepRegistration) {
            navigate('/login');
        }
    }, [canAccessStepRegistration, navigate]);

    return (
        <>
            <LoginHeader isBonus={true}/>
            <Box component="form" noValidate sx={{
                overflowX: 'hidden',
                overflowY: 'hidden',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
                <Box sx={{ flexGrow: 1, textAlign: 'center', fontFamily: 'Ruffina' }}>
                    <Typography variant="body2" align="center" sx={{ color: 'gray', mb: 3, fontFamily: 'Ruffina' }}>
                        Passaggio 3 di 3
                    </Typography>
                    <CheckCircleOutlineOutlined sx={{ color: '#4CAF50', fontSize: 40, mb: 2 }} />
                    <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Ruffina', maxWidth: '30%', mx: 'auto' }}>
                        Accesso confermato
                    </Typography>

                    <Typography variant="body1" align="center" sx={{ color: 'gray', mb: 3, fontFamily: 'Ruffina', maxWidth: '18%', mx: 'auto' }}>
                    A breve riceverai un'e-mail di benvenuto con tutte le informazioni per accedere al servizio dell’Annuario Pontificio.                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleContinue}
                            sx={{ background: '#FDC800', color: 'black' }}
                        >
                            ACCEDI ALL'ANNUARIO
                        </Button>
                    </Box>
                </Box>

                <Footer />
            </Box>
        </>
    );
};

export default BonusAccepted;
