import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, TextField, Button, IconButton, Checkbox, FormControlLabel, Typography, Link } from '@mui/material';
import { Visibility, VisibilityOff, Refresh as RefreshIcon } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import apiService from '../services/captchaservice';
import loginService from '../services/loginService';
import { useAuth } from '../context/authContext';
import ErrorDialog from '../errorDialog';
import { RegistrationContext } from '../context/registrationProvider';
import { jwtDecode } from 'jwt-decode';
import ConfirmationDialog from '../dialog';

const LoginForm = () => {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const { canAccessStepRegistration, allowStepRegistrationAccess, userData, setUserData } = useContext(RegistrationContext);
    const [captcha, setCaptcha] = useState('');
    const [captchaImage, setCaptchaImage] = useState('');
    const [captchaInput, setCaptchaInput] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [timer, setTimer] = useState(30);
    const timerRef = useRef(null);
    const attemptsRef = useRef(0);
    const navigate = useNavigate();
    const { user, rememberMeEffect, saveUser, saveToken } = useAuth() || {};
    const [openDialogError, setOpenDialogError] = useState(false);
    const [messageError, setMessageError] = useState('');
    const [redirectSubscription, setRedirect] = useState(false);

    const handleCancelErrorExit = () => {

        if (!redirectSubscription) {
            setOpenDialogError(false);
        } else {
            redirectSubscriptionActived();
        }

    };

    const resetPassword = () => {
        setMessageError('Per resettare la password contattare annuariopontificio@sds.va');
        setOpenDialogError(true);
    }

    const redirectSubscriptionActived = () => {
        allowStepRegistrationAccess();
        navigate('/subscription/off')
    }

    useEffect(() => {
        if (user) {
            setCredentials({ username: user.username, password: atob(user.password) });
        }
    }, [user]);

    const generateCaptcha = async () => {
        if (attemptsRef.current < 3) {
            attemptsRef.current += 1;
            const randomNumber = Math.floor(Math.random() * 100);
            const encodedCaptcha = btoa(randomNumber.toString());
            const data = { captchark: encodedCaptcha };

            try {
                const response = await apiService.postCaptchaRequest(data);
                const base64Image = response.captcha;
                setCaptchaImage(`data:image/png;base64,${base64Image}`);
                setCaptcha(encodedCaptcha);
            } catch (error) {
                console.error('Errore nella generazione del captcha:', error);
            }
        }
    };

    const startTimer = () => {
        clearInterval(timerRef.current);
        setTimer(30);
        timerRef.current = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(timerRef.current);
                    generateCaptcha();
                    startTimer();
                    return 30;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    useEffect(() => {
        generateCaptcha();
        startTimer();

        return () => clearInterval(timerRef.current);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prev => ({ ...prev, [name]: value }));
    };

    const validateEmail = (email) => {
        const emailOrUsernameRegex = /^[a-zA-Z0-9._@-]+$/;
        return emailOrUsernameRegex.test(email);
    };

    const handleRedirect = () => {
        navigate('/register', { state: { isRegister: true } });
    }

    const validatePassword = (password, username) => {
        const errorPassword = 'Password non valida';
        const specialChars = /[!#$%&()*+,\-.:;=?@^_]/;
        const lengthValid = password.length >= 8 && password.length <= 30;
        const upperCaseValid = /[A-Z]/.test(password);
        const lowerCaseValid = /[a-z]/.test(password);
        const numberValid = /\d/.test(password);
        const specialCharValid = specialChars.test(password);
        const usernameIncluded = password.includes(username);

        if (!lengthValid) return errorPassword;
        if (!upperCaseValid) return errorPassword;
        if (!lowerCaseValid) return errorPassword;
        if (!numberValid) return errorPassword;
        if (!specialCharValid) return errorPassword;
        if (usernameIncluded) return errorPassword;

        return null;
    };

    const validateForm = () => {
        const newErrors = {};

        if (!credentials.username) {
            newErrors.username = 'L\'email è obbligatoria';
        } else if (!validateEmail(credentials.username)) {
            newErrors.username = 'Inserisci un\'email valida';
        }

        if (!credentials.password) {
            newErrors.password = 'La password è obbligatoria';
        } else {
            const passwordError = validatePassword(credentials.password, credentials.username);
            if (passwordError) {
                newErrors.password = passwordError;
            }
        }

        if (!captchaInput) {
            newErrors.captcha = 'Il captcha è obbligatorio'
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const resetCaptchaAttempts = () => {
        attemptsRef.current = 0;
        generateCaptcha();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const captchaData = {
                    captcha: captchaInput,
                    captchark: captcha,
                };
                const captchaResponse = await apiService.postCaptchaVerification(captchaData);

                if (captchaResponse.status === 200) {
                    try {
                        const loginData = {
                            username: credentials.username,
                            password: btoa(credentials.password),
                        };
                        const loginResponse = await loginService.login(loginData);
                        if (loginResponse.status === 202) {
                            const token = loginResponse.data.token;

                            if (token) {
                                try {
                                    const decode = jwtDecode(token);

                                    const userRole = decode.role;

                                    if (userRole == 'admin') {
                                        saveToken(token, userRole);
                                        saveUser(loginData, rememberMe);
                                        navigate("/back/home");
                                    } else {
                                        saveToken(token, userRole);
                                        saveUser(loginData, rememberMe);
                                    }

                                } catch (error) {
                                    console.error(error);
                                }

                            }
                        } else {
                            console.log(loginResponse);
                        }
                    } catch (loginError) {
                        console.log(loginError);
                        if (loginError !== undefined) {
                            console.log(loginError);
                            if (loginError.response.data.statusCodeValue === 401) {
                                setRedirect(true);
                                setMessageError(loginError.response.data.message);
                                setUserData({
                                    idAccountBraintree: userData.idAccountBraintree,
                                    nome: userData.nome,
                                    cognome: userData.cognome,
                                    email: userData.email,
                                    password: btoa(userData.password),
                                    incarico: userData.incarico,
                                    dataNascita: userData.dataNascita,
                                    cellulare: userData.cellulare,
                                    externalId: loginError.response.data.externalKey,
                                    idAccount: loginError.response.data.accountId
                                })
                                console.log(userData);
                                redirectSubscriptionActived();
                            } else {
                                setMessageError(loginError.response.data.message);
                                setOpenDialogError(true);
                                setRedirect(false);
                            }
                        }
                    }
                } else {
                    setErrors({ captcha: 'Il captcha non corrisponde' });
                }
            } catch (captchaError) {
                console.log(captchaError);
                setErrors({ captcha: 'Il captcha non corrisponde' });
            }

        }
    };

    const progressBarWidth = `${(timer / 30) * 100}%`;

    return (
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ overflowX: 'hidden', overflowY: 'hidden', display: 'flex', alignContent: 'center', alignItems: 'center', flexDirection: 'column', marginRight: '35px' }}>
            <Typography variant="h4" align="center" gutterBottom sx={{fontFamily:'Rufina'}}>
                Accedi
            </Typography>
            <Typography variant="body2" align="center" sx={{ color: 'gray', mb: 3, fontFamily:'Open Sans' }}>
                Inserisci le credenziali per accedere al tuo account
            </Typography>

            <TextField
                name="username"
                label="E-mail/Username"
                fullWidth
                margin="normal"
                value={credentials.username}
                onChange={handleInputChange}
                error={!!errors.username}
                helperText={errors.username}
                InputLabelProps={{
                    style: { fontSize: '16px', color: '#666', top: '5px' }
                }}
                sx={{ width: '536px' }}
            />
            <TextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                margin="normal"
                value={credentials.password}
                onChange={handleInputChange}
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    ),
                }}
                InputLabelProps={{
                    style: { fontSize: '16px', color: '#666', top: '5px' }
                }}
                sx={{ width: '536px' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                        />
                    }
                    label={<span style={{ color:'#00000099', fontWeight: '400',fontFamily:'Open Sans', fontSize:'16px'}}>Ricorda le mie credenziali</span>}
                />

                <Link
                    onClick={resetPassword}
                    sx={{ color: '#0587D1', textDecoration: 'none', fontSize: '16px', fontFamily: 'Open Sans', cursor: 'pointer', marginLeft: '140px', fontWeight: '400' }}
                >
                    Recupera password
                </Link>
            </Box>
            <Typography variant="body2" align="left" sx={{ mt: 3, width: '536px',fontFamily:'Open Sans' }}>
                INSERISCI CODICE DI SICUREZZA
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'flex-start', mt: 1, width: '536px' }}>
                <img src={captchaImage} alt="Captcha" style={{ width: '150px', height: '50px', textAlign: 'left' }} />
                <IconButton onClick={resetCaptchaAttempts}>
                    <RefreshIcon />
                </IconButton>
            </Box>

            <Box
                sx={{
                    width: '25%',
                    height: '10px',
                    backgroundColor: 'gray',
                    position: 'relative',
                    mt: 1,
                    borderRadius: '0px',
                    overflow: 'hidden',
                    marginLeft: '-330px'
                }}
            >
                <Box
                    sx={{
                        width: progressBarWidth,
                        height: '100%',
                        backgroundColor: '#05A8F4',
                        transition: 'width 1s linear',
                    }}
                />
            </Box>

            <TextField
                name="captchaInput"
                label="Codice di Sicurezza"
                fullWidth
                margin="normal"
                value={captchaInput}
                onChange={(e) => setCaptchaInput(e.target.value)}
                error={!!errors.captcha}
                helperText={errors.captcha}
                InputLabelProps={{
                    style: { fontSize: '16px', color: '#666', top: '5px' }
                }}
                sx={{ width: '200px', marginLeft: '-330px' }}
            />
            <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2, background: '#FDC800', color: 'black', width: '532px' }}
            >
                Accedi
            </Button>
            <Typography align="center" sx={{ mt: 2, fontFamily: 'Open Sans', fontSize: '15px' }}>
                <Link component="button" onClick={handleRedirect} sx={{ textDecoration: 'none' }}>
                    CREA UN NUOVO ACCOUNT
                </Link>
            </Typography>

            <ErrorDialog
                onClose={handleCancelErrorExit}
                open={openDialogError}
                title="Attenzione"
                message={messageError}
            />

        </Box>
    );
};

export default LoginForm;
