import { Box } from '@mui/material';
import Sidebar from './sidebar';
import Footer from '../footer';
import Header from '../header';
import { useEffect, useState } from 'react';
import TableUser from './tableUser';
import '../../css/style.css';
import EditUser from './editUser';
import CreateUser from './createUser';
import TableCode from './tableCode';

const Layout = () => {
    const [selectedComponent, setSelectedComponent] = useState('home');
    const [userToEdit, setUserToEdit] = useState(null);

    const handleMenuClick = (component) => {
        setSelectedComponent(component);
    };

    const handleEditClick = (user) => {
        setUserToEdit(user);
        setSelectedComponent('edit');
    };

    const handleBackToTable = () => {
        setUserToEdit(null);
        setSelectedComponent('home');
    };
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                overflow: 'hidden'
            }}
        >
            <Header
                isRegister={true}
                sx={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 10,
                }}
            />
            <Sidebar
                onMenuClick={handleMenuClick}
                sx={{
                    position: 'fixed',
                }}
            />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    marginTop: '-30px',
                    marginLeft: '240px',
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                        height: 'calc(100vh - 64px)',
                        overflowY: 'auto',

                    }}
                >
                    {selectedComponent === 'home' && (
                        <Box sx={{ padding: 2 }}>
                            <TableUser onEditClick={handleEditClick} />
                        </Box>
                    )}

                    {selectedComponent === 'edit' && userToEdit !== undefined && (
                        <Box sx={{ padding: 2 }}>
                            <EditUser externalKey={userToEdit} onBack={handleBackToTable} />
                        </Box>
                    )}

                    {selectedComponent === 'register' && (
                        <Box sx={{ padding: 2 }}>
                            <CreateUser />
                        </Box>
                    )}

                    {selectedComponent === 'code' && (
                        <Box sx={{ padding: 2 }}>
                            <TableCode />
                        </Box>
                    )}
                </Box>
            </Box>

            <Footer
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                }}
            />
        </Box>
    );
};

export default Layout;
