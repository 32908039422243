import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); 
  const [rememberMe, setRememberMe] = useState(false);
  const [token, setToken] = useState(null);
  const [role, setRole] = useState('');
  const saveUser = (userData, rememberMe) => {
    setUser(userData);
    setRememberMe(rememberMe);
  };

  const saveToken = (token, role) =>{
    setToken(token);
    setRole(role);
  }

  const logout = () => {
    setUser(null);
    setRememberMe(false);
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ token, role, user, rememberMe, saveUser, logout, saveToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
