import axios from 'axios';
import api from '../../utils/proxy.js';

const incaricoService = {
    getAll: async () => {
        try {
            const response = await axios.post(api.path + api.incaricogetAll, null, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response; 
        } catch (error) {
            console.error('Errore durante la richiesta del captcha:', error);
            throw error;
        }
    },
    
};

export default incaricoService;
